import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="timer"
export default class extends Controller {
  static values = {
    sessionstarttime: Number,
    challengestarttime: Number,
    showhint: Boolean,
  };

  static targets = ["currenttime", "trigger"];

  connect() {
    this.timer();
  }

  timer() {
    const date = new Date(this.sessionstarttimeValue);
    const challengeStartDate = new Date(this.challengestarttimeValue);

    var refreshTimer = setInterval(() => {
      var timeElapsed = new Date().getTime() - date.getTime();

      const challengeTimeElapsed =
        (new Date().getTime() - challengeStartDate.getTime()) / 1000;

      if (challengeTimeElapsed > 600 && this.showhintValue) {
        this.triggerTarget.classList.remove("is-dark-light");
        this.triggerTarget.classList.add("is-danger");
      }

      var parts = {
        // timestamp to hours
        hours: Math.floor(timeElapsed / (1000 * 60 * 60)),
        minutes: Math.floor((timeElapsed / 1000 / 60) % 60),
        seconds: Math.floor((timeElapsed / 1000) % 60),
      };

      if (parts.hours === 0) {
        delete parts.hours;
      }

      var output = Object.keys(parts)
        .map((part) => {
          if (parts[part].toString().length > 1) {
            return `${parts[part]}`;
          } else {
            return `0${parts[part]}`;
          }
        })
        .join(":");

      if (this.hasCurrenttimeTarget) {
        this.currenttimeTarget.textContent = output;
      }
    }, 1000);
  }
}
