import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "slide", "bullet" ]

  initialize() {
    this.index = 0
    this.showCurrentSlide()
  }

  next() {
    this.index++
    this._minmax()
    this.showCurrentSlide()
  }

  previous() {
    this.index--
    this._minmax()
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      if (index === this.index) {
        element.classList.remove("is-hidden")
      } else {
        element.classList.add("is-hidden")
      }
    })
    this.bulletTargets.forEach((element, index) => {
      if (index === this.index) {
        element.classList.add("has-text-white")
      } else {
        element.classList.remove("has-text-white")
      }
    })
  }

  _minmax() {
    if (this.index > this.slideTargets.length - 1) {
      this.index = 0
    } else if (this.index < 0) {
      this.index = this.slideTargets.length - 1
    }
  }
  
  
}