import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accessibility"
export default class extends Controller {
  connect() {
    const accessibleTargets = this._accessible_targets()
    accessibleTargets.forEach((target) => this._toggle_accessibility(target))
  }

  accessible(event) {
    const target = event.currentTarget.dataset.accessibilityTarget
    const message = event.currentTarget.dataset.accessibilityMessage
    var currentTargets = this._accessible_targets()
    if (!this._accessible_targets().includes(target)) {
      const confirm = window.confirm(message)
      if (confirm) {
        currentTargets.push(target)
        this._toggle_accessibility(target)
        window.sessionStorage.setItem("accessible-targets", currentTargets.join(","))
      }
    } else {
      currentTargets.splice(currentTargets.indexOf(target), 1)
      this._toggle_accessibility(target)
      window.sessionStorage.setItem("accessible-targets", currentTargets.join(","))
    }
  }

  _toggle_accessibility(target) {
    const targetClassName = "accessible-" + target
    const buttonClassName = "accessible-" + target + "-button"
    document
        .querySelectorAll(`[data-accessibility-name="${targetClassName}"]`)
        .forEach((targetElement) => targetElement.classList.toggle('accessible'))
    document
        .querySelectorAll(`[data-accessibility-name="${buttonClassName}"]`)
        .forEach((targetElement) => targetElement.classList.toggle('is-active'))
  }

  _accessible_targets() {
    const accessibleTargets = window.sessionStorage.getItem("accessible-targets")
    if (accessibleTargets) {
      return accessibleTargets.split(",")
    } else {
      return []
    }
  }
}
