import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  hidden(event) {
    this.toggle_class("is-hidden", event.currentTarget);
  }

  active(event) {
    this.toggle_class("is-active", event.currentTarget)
  }

  loading(event) {
    this.toggle_class("is-loading", event.currentTarget)
  }

  toggle_class(css_class, currentTarget) {
    if (!currentTarget.dataset.toggleTarget) {
      // console.log(currentTarget)
      currentTarget.classList.toggle(css_class)
    } else {
      const TARGETS = currentTarget.dataset.toggleTarget.split(",");
      // console.log(TARGETS)
      TARGETS.forEach((target) =>
      document
        .querySelectorAll(`[data-toggle-name="${target}"]`)
        .forEach((target) => target.classList.toggle(css_class))
    );
    }
  }


}