import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cookies"
export default class extends Controller {
  static targets = ["banner"];

  connect() {
    if (document.cookie.includes("cookies=accepted") || document.cookie.includes("cookies=rejected")) {
      this._hide();
    } 
  }

  accept() {
    this._hide();
    document.cookie = "cookies=accepted";
  }

  reject() {
    document.cookie = "cookies=rejected";
    this._hide();
  }

  _hide() {
    this.bannerTarget.classList.add("is-hidden");
  }

}
