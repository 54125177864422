import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ratings"
export default class extends Controller {
  static targets = ["rating1", "rating2", "rating3", "rating4", "rating5", "formvalue"];

  static values = {
    rating: Number,
  };

  connect() {
    this._setRatingValue(this.ratingValue);
  }

  hover(event) {
    const currentTargetName = event.currentTarget.dataset.ratingsTarget;

    const currentTargetNumber = currentTargetName.charAt(
      currentTargetName.length - 1
    );

    this._targets.slice(0, currentTargetNumber).forEach((target) => {
      target.classList.add("star-hover");
    });
    this._targets.slice(currentTargetNumber, 5).forEach((target) => {
      target.classList.remove("star-hover");
      target.classList.remove("is-active");
    });
  }

  unhover(event) {
    // iterate through all targets

    this._targets.forEach((target) => {
      target.classList.remove("star-hover");
    });

    this._setRatingValue(this.ratingValue);
  }

  setRating(event) {
    const currentTargetName = event.currentTarget.dataset.ratingsTarget;

    const currentTargetNumber = currentTargetName.charAt(
      currentTargetName.length - 1
    );

    this._setRatingValue(currentTargetNumber);
  }
  
  _setRatingValue(value) {
    this.ratingValue = value;
    this.formvalueTarget.value = value;

    this._targets.slice(0, value).forEach((target) => {
      target.classList.remove("star-hover");
      target.classList.add("is-active");
    });
    this._targets.slice(value, 5).forEach((target) => {
      target.classList.remove("star-hover");
      target.classList.remove("is-active");
    });
  }

  _targets = [
    this.rating1Target,
    this.rating2Target,
    this.rating3Target,
    this.rating4Target,
    this.rating5Target,
  ];
}
